import { Storage } from 'aws-amplify';
import { v4 as uuidv4 } from 'uuid';

const configFile = process.env.REACT_APP_CONFIG_FILE;

export const domains = {
    prod: [
        "https://yle.fi",
        "https://abitreenit.yle.fi",
        "https://appen.yle.fi",
        "https://beta.yle.fi",
        "https://buu.yle.fi",
        "https://careers.yle.fi",
        "https://dashboard.yle.fi",
        "https://elavaarkisto.fi",
        "https://folk.yle.fi",
        "https://julkaisut.yle.fi",
        "https://kielikoulu.yle.fi",
        "https://kioski.yle.fi",
        "https://kirjeet.yle.fi",
        "https://kyselyt-dashboard.yle.fi",
        "https://link.yle.fi",
        "https://m.yle.fi",
        "https://ohjelmaopas.yle.fi",
        "https://oppiminen.yle.fi",
        "https://palaute.yle.fi",
        "https://pbi2019.yle.fi",
        "https://pressi.yle.fi",
        "https://programguide.yle.fi",
        "https://radiator.aws.yle.fi",
        "https://radiosuomi.fi",
        "https://recruiting.yle.fi",
        "https://rekry.yle.fi",
        "https://rekrytering.yle.fi",        
        "https://rest-mq-radiator.apps.yle.fi",
        "https://rest-mq-radiator.apps-test.yle.fi",
        "https://rso.fi",
        "https://stromso.yle.fi/",
        "https://tech.yle.fi",
        "https://ukk.yle.fi",
        "https://uusi-dashboard.yle.fi",
        "https://www.elavaarkisto.fi",
        "https://www.radiosuomi.fi",
        "https://www.rso.fi",
        "https://www.x3m.fi",
        "https://x3m.fi",
        "https://x3m.yle.fi",
        "https://www.yle.fi",
        "https://www.ylebloggen.fi", 
        "https://www.ylex.fi", 
        "https://ylex.fi", 
        "https://ylex.yle.fi",
        "https://ylebloggen.fi",
        "https://yleklassinen.fi",
        "https://ylenklassinen.fi",        
        "https://yleradio1.fi"
    ],
    test: [
        "https://yle.fi",
        "https://abitreenit.test.yle.fi",
        "https://link.test.yle.fi",
        "https://radiator-test.aws.yle.fi",  
        "https://test.rso.fi",
        "https://ylefi-ohjelmaopas.test.yle.fi",
        "https://ylefi-recruiting.test.yle.fi",
        "https://ylefi-www.test.yle.fi",
    ],
    local: [
        "https://yle.fi",
        "https://abitreenit.test.yle.fi",
        "https://test.rso.fi",
        "https://domain-redirector-test.c4t.yle.fi"
    ],
}

export const originalExists = (domain, original, id, configJson) => {

    if (domain && domain !== "https://yle.fi" && original.startsWith("^")) original = original.substring(1);
    if (!original.startsWith("http") && !original.startsWith("/")) original = "/" + original;

    if (domain && domain !== "https://yle.fi") original = domain + original;
    if (domain && !domain.startsWith("https://link.")) original = original.toLowerCase();

    for (const item of configJson) {
        if (item.original === original && item.id !== id) return true;
    }
    return false;
};

export const getItem = (id, configJson) => {
    for (const item of configJson) { 
        if (item.id === id) return item;
    }
    return null;
};

export const search = (str, configJson) => {
    const res = [];
    if (!configJson) return res;
    if (!str) return configJson;    
    //str = str.toLowerCase();
    for (const item of configJson) { 
        if (item.original.includes(str) || item.redirect.includes(str)) res.push(item);
    }
    return res;
};

export const createFormItem = (elements) => {
    let original = elements.original.value;
    let domain = elements.domain?.value;
    let redirect = elements.redirect.value;
    let statusCode = elements.statusCode.value;
    let startTime = elements.startTime.value;
    let endTime = elements.endTime.value;

    if (domain && !domain.startsWith("https://link.")) original = original.toLowerCase();

    if (original.startsWith("https://yle.fi")) original = original.substring(14);
    if (original.startsWith("http://yle.fi")) original = original.substring(13);
    if (original.startsWith("https://www.yle.fi")) original = original.substring(18);
    if (original.startsWith("http://www.yle.fi")) original = original.substring(17);
    
    if (original !== "" && !original.startsWith("http") && !original.startsWith("/") && !original.startsWith("^")) original = "/" + original;
    if (domain && domain !== "https://yle.fi") original = domain + original;

    if (!redirect.toLowerCase().startsWith("http")) {
        if (!redirect.startsWith("/")) redirect = "/" + redirect;
        redirect = "https://yle.fi" + redirect;
    }

    return {
        id: uuidv4(),
        original,
        redirect,
        statusCode,
        startTime,
        endTime,
    }
};

export const cleanS3Json = (data) => {    
    const items = [];
    for (const item of data.rules) {
        item.startTime = item.startTime.indexOf('T') > -1 ? item.startTime.substring(0,item.startTime.indexOf("T")) : item.startTime;
        item.endTime = item.endTime.indexOf('T') > -1 ? item.endTime.substring(0,item.endTime.indexOf("T")) : item.endTime;
        items.push(item);
    }
    return items;
};

export const getConfigFile = async () => {    

    //const now = new Date();
    //console.log("GETTING CONFIG FROM S3");
    try {
        const response = await Storage.get(configFile, { 
            download: true,
            cacheControl: 'no-cache',
        });
        const s3Json = JSON.parse(await response.Body.text());
        //console.log("GOT CONFIG FROM S3: " + (new Date() - now));
        return cleanS3Json(s3Json);
    }
    catch (err) {
        console.log(err);
        if (err.toString().toLowerCase().includes("nosuchkey")) {
            return JSON.stringify({
                rules: [],
                refreshTime: 60
            })
        }       
    }
};

export const uploadConfig = async (config) => {

    const newConfig = JSON.parse(JSON.stringify(config));
    //const now = new Date();
    //console.log("UPLOADING CONFIG TO S3");

    const s3Json = {
        rules: [],
        refreshTime: 60
    }

    for (const item of newConfig) {
        item.startTime = item.startTime !== "" && item.startTime.indexOf('T') === -1 ? item.startTime += "T00:00:00.000Z" : item.startTime;
        item.endTime = item.endTime !== "" && item.endTime.indexOf('T') === -1 ? item.endTime += "T23:59:59.000Z" : item.endTime;
        s3Json.rules.push(item);
    }

    try { 
        await Storage.put(configFile, JSON.stringify(s3Json)); 
        //console.log("CONFIG UPLOADED: " + (new Date() - now));
        return true;
    }
    catch (err) { console.log("err"); }
    return false;
};

export const getInitItem = () => {
    return {
        id: '',
        original: '',
        redirect: '',
        statusCode: '',
        startTime: '',
        endTime: '',
        user: '',
        modified: ''
    }
}

export const checkUrl = (url) => {
    url = url.replace("^", "");
    url = url.replace("(.*)", "");
    url = url.replace(".*", "");
    url = url.replace("?$", "");
    url = url.replace("$", "");
    if (!url.startsWith("http")) url = process.env.REACT_APP_RULES_URL + url;
    return url;
}

